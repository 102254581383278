import React from 'react'

function Footer() {
    return (
        <div className="w-full text-center px-8 flex justify-center items-center" >
            <p>Copyright Jeremy Colegrove 2021. All rights reserved.</p>
        </div>
    )
}

export default Footer
