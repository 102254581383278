import React from 'react'

function HorizontalLine() {
    return (
        <div className="w-8 h-1 my-2 bg-blue-700">
            
        </div>
    )
}

export default HorizontalLine
