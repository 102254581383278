import React from 'react'

// Expects props.image
function Project(props) {
    return (
        <div className=" p-2  mt-16 w-full mx-4 h-auto cursor-pointer"  onClick={()=>window.location.href=props.link}>
            <div className="flex flex-col justify-center md:flex-row">
                <img className="h-60 w-full pb-4 object-cover md:max-w-xs" src={props.image}></img>
                <div className="md:px-10">
                        <h1>
                            {props.title}
                        </h1>
                    
                    <p>
                        {props.description}
                    </p>
                </div>
            </div>
        </div>

    )
}

export default Project
