import React from 'react'

function Scrollable(props) {
    return (
        <div className="absolute z-10  overflow-y-auto w-full" style={{marginTop:'100vh'}}>
            {props.children}
        </div>
    )
}

export default Scrollable
