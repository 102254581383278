import React from 'react'

function PlaybookItem(props) {
    return (
        <div className="flex flex-col md:flex-row items-center py-4 text-center md:text-left">
            <h1 className="text-6xl px-6">
             {props.number}
            </h1>
            <p>
                {props.content}
            </p>
        </div>
    )
}

export default PlaybookItem
