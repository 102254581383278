import React from 'react'
import HorizontalLine from './HorizontalLine'
import Project from './Project'
import pyparser from '../img/pyparser.jpeg'
import twentyfortyeight from '../img/2048.png'
import taskify from '../img/taskify.jpg'
function Projects() {
    return (
        <div id="projects" className="bg-gray-200 w-full py-8 flex flex-col items-center justify-center">
            <h1 className="text-xl">Projects</h1>
            <HorizontalLine/>
            
            {/* All of my projects go here! */}
            <div className="flex flex-wrap justify-center">
                    <Project
                        image={taskify}
                        title="2020 - Founder of Taskify LLC"
                        description="I launched my own agile based kanban board startup! I accomplished everything from the value proposition, to coding version one from the ground up. Then I found some amazing colleagues, and they jumped on board! Together we worked as a team, re-factored, and are still currently implementing new features and functionality so we stand out from the rest of our compeititon. We use: Figma, React.js, Node.js + express, MySql, GitHub, and AWS."
                        link="https://www.google.com/q=taskify"
                        />

                    <Project
                        image={pyparser}
                        title="2019 - Python Parser"
                        description="I wrote this lightweight python parser library to safely evaluate mathematical expressions in python, with some added features! This framework supports variables, multiple equations, function composition. It demonstrates my knowledge of higher order functions, recursive decent parsers, and algorithms."
                        link="https://github.com/Blakletter/PyParser"
                    />

                    <Project
                        image={twentyfortyeight}
                        title="2018 - 2048 Android Game"
                        description="I created my own 2048 mobile game! Created with Gamemaker studio 2.0 (GML) and Java. I used multiple optimization techniques to save and load games quickly, used advanced algorithms for the tiles and created all of the art and graphics."
                        link="https://play.google.com/store/apps/details?id=com.illuminati.twentyfortyeight"
                    />
            </div>
        </div>
    )
}

export default Projects
