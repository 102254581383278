import React, {useRef, useEffect} from 'react'
import cover from '../img/cover3.jpg'
import profile from '../img/profile.jpeg'
import github from '../img/github.png'
import linkdin from '../img/linkdin.png'
import facebook from '../img/facebook.png'
import Project from './Project'
import raindrops from '../img/raindrops.gif'
import resume from '../img/Resume.pdf'


function Header() {
    let words = ["Taskify LLC Founder", "UX Developer/Designer", "University of Minnesota Student", "C/C++ Developer", "Motorcyle Enthusiast", "Java Developer" ]
    let index = 0
    let word = ""
    let ref = null
    let cursor = true


    function write(i) {
        if (!ref) ref = document.getElementById('typing')
        
        let subs = word.substring(0, i)
        ref.innerHTML = subs

        if (subs.length === word.length) {
            setTimeout(()=>clearWord(i), 4000)
        } else {
            setTimeout(function () {write(i+1)}, 1000/word.length)
        }
    }

    function clearWord(i) {
        if (!ref) ref = document.getElementById('typing')


        if (i >= 0) {
            let subs = word.substring(0, i)
            ref.innerHTML = subs
            setTimeout(()=>clearWord(i-1), 1000/word.length)
        } else {
            if (index == words.length - 1) index = -1
            word = words[++index]

            setTimeout(()=>write(i), 2000)
        }
    }

    function blink() {
        if (cursor) {
            ref.style.borderRight = "solid"
            ref.style.borderRightWidth = "1px"
        } else {
            ref.style.borderRight = "none"
        }
        cursor = !cursor
    }

    useEffect(() => {
        ref = document.getElementById('typing')
        word = words[index]
        write(1)
        let blinkInterval = setInterval(blink, 1000)
        return () => {
            clearInterval(blinkInterval)
        }
    }, [])


    return (
        <div className="z-0 top-0 fixed flex flex-col text-gray-50 justify-center items-center w-full h-screen bg-gray-200" style={{objectFit:'cover', backgroundSize:'cover', backgroundImage:`linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${cover})` }}>
            {/* <div className="z-0 opacity-10 top-0 absolute w-full h-screen " style={{ backgroundImage:`url(${raindrops})`, objectFit:'cover', backgroundSize:'cover', backgroundBlendMode:'multiply'}}>
            </div> */}
            
            <div className="z-10 flex flex-col justify-center items-center">

                <img className="w-32 h-32 lg:w-32 lg:h-32 rounded-full border-2 border-solid" src={profile}></img>
                <h1 className="text-2xl lg:text-5xl p-4 text-center">Jeremy Colegrove</h1>
                <h2 ref={ref} id="typing" className="h-6 lg:h-10 text-md lg:text-2xl" style={{borderRightWidth:'1px', paddingRight:'4px', borderRightColor:'white'}}></h2>
            
                {/* Now provide all of the links */}
                <div className="flex pt-4">
                    <a className="w-6 h-6 mx-2" target="_blank" href="https://github.com/JeremyMColegrove?tab=repositories">
                        <img src={github} alt="Github Link"></img>
                    </a>
                    <a className="w-6 h-6 mx-2" target="_blank" href="https://www.linkedin.com/in/jcoleg/">
                        <img src={linkdin} alt="LinkedIn Link"></img>
                    </a>
                    
                    <a className="w-6 h-6 mx-2" target="_blank" href="https://www.facebook.com/jeremy.colegrove">
                        <img src={facebook} alt="Facebook Link"></img>
                    </a>
                </div>
                <div className=" my-8">
                        <a href={resume} download="Resume_download.pdf" className=" hover:text-blue-400 px-4 py-2 text-gray-200 rounded-md " >Download Resume</a>

                </div>
                <div className=" ">
                        <a href="#contact" className=" hover:text-blue-400 px-4 py-2 text-gray-200 rounded-md " >Contact Me</a>

                </div>
            </div>

        </div>
    )
}

export default Header
