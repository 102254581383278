import React from 'react'
import Footer from './Footer'
import HorizontalLine from './HorizontalLine'
var AWS = require('aws-sdk');
// AWS.config.update({region: 'Ohio'});
AWS.config.update({
    accessKeyId: process.env.REACT_APP_ID,
    secretAccessKey: process.env.REACT_APP_SECRET,
    "region": "us-east-2"   
}); // for simplicity. In prod, use loadConfigFromFile, or env variables

// Create sendEmail params 
const SES = new AWS.SES({region:"us-east-2"})

const EMAIL_TESTING = false


function ContactMe() {
    var [sending, setSending] = React.useState(false)
    var [sent, setSent] = React.useState(false)


    function handleTesting() {
        //Wait for two seconds
        setTimeout(()=>{
            setSent(true)
        }, 2000)
    }


    function handleSubmit(e) {
        e.preventDefault()
        setSending(true)
        //Acts like the email being sent
        if (EMAIL_TESTING) return handleTesting()


        var params = {
            Destination: { /* required */
              CcAddresses: [],
              ToAddresses: [
                'jeremymcolegrove@gmail.com',
                /* more items */
              ]
            },
            Message: { /* required */
              Body: { /* required */
                Html: {
                 Charset: "UTF-8",
                 Data: document.getElementById('body').value
                },
               },
               Subject: {
                Charset: 'UTF-8',
                Data: `New Message from ${document.getElementById('name').value}`
               }
              },
            Source: 'jeremymcolegrove@gmail.com', /* required */
            ReplyToAddresses: [document.getElementById('reply').value],
          };
        //Send using amazon SES
        // Create the promise and SES service object
        
        var sendPromise = SES.sendEmail(params).promise()
        
        sendPromise.then(()=>setSent(true))
            .catch((error)=>{
                setSending(false)
                alert(error)
            });
    }
    return ( 
        <div id="contact" className="flex flex-col py-8 text-gray-50 ajustify-center items-center bg-gray-800 h-auto " style={{minHeight:"100vh"}}>
            <h1 className="text-xl">
                Connect with me
            </h1>
            <HorizontalLine/>
            <p className="px-4 md:text-left text-center">
                I always like to talk with people in the industry!
            </p>

            <form action="" className="w-screen p-4 flex flex-col justify-center items-center max-w-2xl text-gray-800" onSubmit={handleSubmit}>
                <div className="flex justify-center flex-wrap w-full">
                    <input id="reply" className="w-full p-2 my-2"  type="email" placeholder="replyemail@domain.com" required/>
                    <input id="name" className="w-full p-2 my-2"  type="text" placeholder="John Smith" required/>
                </div>

                <textarea id="body" className="h-24 w-full p-2 m-4" placeholder="Write a message..." required/>

                {!sent &&
                    <input disabled={sending} style={{opacity:sending?'50%':'100%'}} className="cursor-pointer w-full p-2 m-4 " type="submit" value="Send"/>}
                    {sent && <div className="w-full text-gray-50 flex justify-center items-center p-2 m-4 bg-green-500">
                        Message sent!
                        </div>}

            </form>

            <Footer/>
        </div>
    )
}

export default ContactMe
