import React, {useEffect} from 'react'

function Navigation(props) {
    let frames = 60
    let start = 100
    function scrollEvent() {
        // console.log(window.scrollY)
        let y = window.scrollY
        
        let color = (1-((y-start) / frames)) * 255;
        let navbar = document.getElementById("navbar")
        navbar.style.opacity =  (y-start) / frames
        document.getElementById('parent').style.color = `rgb(${color}, ${color}, ${color})`
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent)
        }
    }, [])

    return (
        <div  id="parent" className="fixed w-full z-20 flex  h-12  text-gray-100 justify-between">
            <div className="absolute flex w-full z-20 p-3 justify-between items-center">
                {/* Left Navigation Bar */}
                <p>

                </p>
                
                {/* Right side of navigation bar */}
                <div className="flex items-center">
                    <a href="#aboutme" className="hover:text-blue-400 px-2">About Me</a>
                    <a href="#projects" className="hover:text-blue-400 px-2">Projects</a>
                    <a href="#playbook" className="hover:text-blue-400 px-2">My Playbook</a>


                </div>
            </div>
            <div id="navbar" className="absolute w-screen z-10 opacity-0  h-full shadow-md bg-gray-50">
            </div>
        </div>
    )
}

export default Navigation
