import React from 'react'

function Skill(props) {
    return (
        <div className="w-3/4 py-2">
            <p className="pl-2">{props.title}</p>
            <div className="w-full h-4 rounded-full bg-gray-600">
                <div className=" h-full rounded-full "  style={{width:props.progress, background: 'linear-gradient(to right, rgb(220, 220, 255), rgb(255, 255, 255))'}}>
                    
                </div>
            </div>
        </div>
    )
}

export default Skill
