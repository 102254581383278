import React from 'react'
import HorizontalLine from './HorizontalLine'
import personal from '../img/personal.jpg'
import Skill from './Skill'
import resume from '../img/Resume.pdf'
function AboutMe() {
    return (
        // style={{backgroundImage:`url(${aboutmebg})`}}
        <div id="aboutme" className="text-gray-100  flex flex-col justify-center items-center bg-gray-800 w-full h-auto py-12 px-4" style={{minHeight:'100vh'}}>
            <h1 className="text-xl">
                About Me
            </h1>
            <HorizontalLine/>
            <div className="flex flex-col mt-10 lg:flex-row">
                <div className="flex flex-col mx-8 mb-8 items-center justify-center lg:w-1/2" >
                    <img src={personal} className="h-48 w-48 rounded-full border-solid border-2 " style={{ aspectRatio:'1'}}  />

                    <p className="w-full md:w-3/4 lg:w-full pt-8 md:text-center" >
                        Hey there! I'm Jeremy. I'm currently pursuing a degree in Computer Science from the University of Minnesota. I previously obtained my AA degree in Businesses Administration from Rasmussen College. I started coding when I was about 10 years old, since then I have released over ten mobile apps, launched a task management company with my colleagues, and developed a passion for algorithms and data structures. My favorite language is C/C++, I like to ride motorcycles, and I own some Dogecoin!
                    </p>                
                </div>

    
                <div className="flex flex-col  items-center lg:w-1/2">
                    <h1 className="text-lg block ">Skills</h1>

                    <Skill title="C/C++" progress="60%"/>
                    <Skill title="React JS" progress="95%"/>
                    <Skill title="Java" progress="90%"/>
                    <Skill title="Entrepreneurship" progress="78%"/>
                    <Skill title="Motorcycles" progress="70%"/>

                    <div className=" my-8">
                        <a href={resume} download="Resume_download.pdf" className="bg-gray-50 hover:bg-gray-400 px-4 py-2 text-gray-800 rounded-md my-8" >Download Resume</a>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutMe
