import React from 'react'
import HorizontalLine from './HorizontalLine'
import PlaybookItem from './PlaybookItem'

function Journey() {
    return (
        <div id="playbook" className="w-full bg-gray-300 flex flex-col justify-center items-center py-8" style={{height:"min(100vh, 100%)"}}>
            

    <p className="w-full flex flex-col justify-center items-center  md:w-3/4  bg-gray-50 shadow-lg p-8">
        <div>
        
        </div>
        {/* <h1 className="text-xl">My Journey</h1> */}
        


        <div className="text-center">
            When most people ask me who I am, I give them an answer like above. Short, quick, and sweet. <br/>Here is a way better way to get to know me, I like to call it my <b>playbook</b>.

        </div>
        <br/>
        <h1 className="text-xl">My Playbook</h1>

        <HorizontalLine/>
        <PlaybookItem
            number="1"
            content='
                I believe life is about balance! I am not the kind of person that sees the world through a black and white lens, code, or ones and zeros! We all wear different hats, and there is a time and a place to be an engineer but there is also a time and a place to be creative, outgoing, and outspoken. When I was young, the last thing I wanted was to fall into the "nerdy engineer" stereotype. So, I did the only logical thing and went into the people business--waiting tables! It was challenging and fun; I learned so much about myself, met some truly amazing people (who I still hang out with), and learned that we all need to embrace who we are! I had so much fun breaking our district record for the most positive surveys, learning how to speak a different language for my customers, and even blowing up balloon animals for little kids!
            '/>
        <PlaybookItem
            number="2"
            content="
                I put enormous (good) pressure on myself, but never put that pressure on other people. However, when I am on a team, I do expect my teammates to try and find where they can fit in and contribute. I enjoy pushing myself to learn new things and grow each day through YouTube videos, books, articles, or my friends. I marvel at the unknown and want to push technology to places we have not gone. I believe there is always a way to do something better and more efficient.
            "/>

        <PlaybookItem
            number="3"
            content="
                I am the kind of person who is not afraid to speak up about bad things happening in the world. I am determined to make change, leave an impact, and leave the world better than I found it. When I was a young boy (maybe 10) I would stand up for my younger brother at playground, and of course get pushed around, but I knew in my heart it is the right thing to do. It is this kind of courage that the world needs.            
            "/>
        <PlaybookItem
            number="4"
            content="
                It is that same courage and motivation that caused me to launch my own company - Taskify! After being in a couple of internships and talking with other engineers and managers, I saw an opportunity. I noticed that almost every business wanted to implement an agile workflow, but seemingly always slipped into the waterfall approach because the tools they were using were too flexible. This is where Taskify steps in! By providing a more structured tool for businesses, they can feel free to do as they please and let Taskify be the ‘scrum master’. It is still in development with an amazing group of friends/colleagues, and I am so excited to see where it goes in the future!
            "/>
        
        <PlaybookItem
            number="5"
            content="
                I question just about everything, regardless of who I hear it from. If we don't question things, we never improve things. I think by looking deeper at the things we think we 'know,' you will be surprised what interesting and not-so-obvious things we’ll find!
            "/>
    </p>


        </div>
    )
}

export default Journey
