import Header from "./components/Header";
import Scrollable from './components/Scrollable.js'
import AboutMe from './components/AboutMe'
import Navigation from "./components/Navigation";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Journey from "./components/Journey";
import ContactMe from "./components/ContactMe";
function App() {

  return (
    <div>
      <Navigation/>
      <Header/>
      <Scrollable>
          <AboutMe/>

          <Journey/>

          <Projects/>
          {/* <Contact/> */}
          <ContactMe/>
      </Scrollable>
      {/* Now we must list about me section */}
      

    </div>
  );
}

export default App;
